export const SORTING_TYPES = [
  'auctionStartDate-ASCENDING',
  'auctionStartDate-DESCENDING',
  'catalogNumber-ASCENDING',
  'catalogNumber-DESCENDING',
  'name-ASCENDING',
  'name-DESCENDING',
  'price.start-ASCENDING',
  'price.start-DESCENDING',
  'price.current-ASCENDING',
  'price.current-DESCENDING',
  'filters.countryPlz-ASCENDING',
  'filters.countryPlz-DESCENDING'
] as const
